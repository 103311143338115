
import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";

import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(blogPostsPageStyle);

export default function Layout(props) {

  const { title, author, children } = props;

  const data = useStaticQuery(
    graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
            file(relativePath: { eq: "assets/img/bg10.jpg" }) {
              childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `
  )
  const imageData = data.file.childImageSharp.fluid
  const classes = useStyles();

  return (

    <div>
      {/* <Header
        brand="Lucilades"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 200,
          color: "primary"
        }}
      /> */}

      <Parallax className={classes.headerColor} filter="rose" small>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem md={8} className={classes.textCenter}>
              <Link to="/">
                <h1 className={classes.title}>
                  &quot;{title || "Puisque les lucilades sont uniques... Il nous en faut un recueil..."}&quot;
              </h1>
                <h4 className={classes.subtitle + " " + classes.avatar}>
                  {author || "VL"}
                </h4>
              </Link>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classes.main}>
        <div className={classes.container}>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              {children}
            </GridItem>
          </GridContainer>


        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://nivenn.io"
                    target="_blank"
                    className={classes.block}
                  >
                    Nivenn
                  </a>
                </ListItem>
                {/* <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://nivenn.io"
                    target="_blank"
                    className={classes.block}
                  >
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="//blog.creative-tim.com/" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/license?ref=mkpr-blog-posts"
                    target="_blank"
                    className={classes.block}
                  >
                    Licenses
                  </a>
                </ListItem> */}
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , made with{" "}
              <Favorite className={classes.icon} /> pour{" "}
              Lulu
            </div>
          </div>
        }
      />
    </div >
  )
}
